<template>
  <div id="app">
      <v-app>
          <AppBar v-if="$route.meta.showHeader == true"/>

          <v-main class="background-app-dark">
              <v-container fluid>
                  <router-view/>
              </v-container>
          </v-main>

          <ManagerTerminalIdModal :TerminalsId="terminals" :showModalTerminal="showModalTerminal"/>
      </v-app>
  </div>
</template>

<script>
import AppBar from "@/components/AppBar";
import ManagerTerminalIdModal from "@/components/ManagerTerminalIdModal";
export default {
    components: {AppBar,ManagerTerminalIdModal},
    data() {
        return {
            terminals: [],
            showModalTerminal: true,
        }
    },
     created () {
        let lang = localStorage.getItem("WORLD_TRAVEL_LANG");
        if(lang){
            this.$i18n.locale = lang
        }

        let terminalIdSelected = this.$mqtt.getTerminalId()
        if(terminalIdSelected) {
            this.showModalTerminal = false
        }

        this.$mqtt.subscribeRetainTerminalId((data) => {
            this.addInTabIfNotExist(this.terminals,data)
        })
    },
    methods: {
        addInTabIfNotExist(tab, number) {
            if (!tab.includes(number)) {
                tab.push(number);
            }
        }
    },
    // watch:{
    //     showModalTerminal: function() {
    //         if(this.showModalTerminal === true ) {   
    //             console.log('dln');           
    //             MQTT.publishMessage('SEND_CONNECTED', 0);
    //         }
            
    //     },
    // }
}
</script>
