<template>
    <div>
        <v-dialog
        v-model="modalLuggageClosed"
        scrollable
        width="700"
        height="600"
        persistent
        >
            <v-card>

                <v-card-title class="d-flex justify-center mb-4">{{$t('baggage_title')}}</v-card-title>

                <v-card-text>

                    <v-row>
                        <v-col>
                            <v-alert
                                icon="mdi-label-percent"
                                color="primary"
                                variant="tonal"
                                text="white"
                            >
                            {{$t('baggage_save_alert')}}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row class="my-4">
                        <v-col>
                                <div>{{$t('baggage_cabin_info')}}</div>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex flex-column my-4">
                        <v-col class="d-flex align-center">
                            <v-icon>mdi-bag-checked</v-icon>
                            <div class="ml-4">{{$t('baggage_accessory_info')}}</div>
                            <v-spacer></v-spacer>
                            <div>{{$t('baggage_included_1')}}</div>
                        </v-col>
                        <v-col class="d-flex align-center">
                            <v-icon>mdi-bag-suitcase</v-icon>
                            <div class="ml-4">{{$t('baggage_cabin_bag_info')}}</div>
                            <v-spacer></v-spacer>
                            <div>{{$t('baggage_included_1')}}</div>
                        </v-col>
                        <v-col v-if="optionsLuggage.checked !== 0 ||optionsLuggage.checkedAdd !== 0" class="d-flex align-center">
                            <v-icon>mdi-bag-carry-on</v-icon>
                            <div class="ml-4">{{$t('baggage_checked_bag_info')}}</div>
                            <v-spacer></v-spacer>
                            <div>{{optionsLuggage.checked + optionsLuggage.checked_Add}} {{$t('baggage_included_count')}}</div>
                        </v-col>
                    </v-row>

                    <v-row class="d-flex justify-end align-center mt-4">
                        <v-col cols="6" v-if="selectedBaggage" class="mb-4">
                            <div class="text-h5 font-weight-bold primary--text">{{$t('baggage_total')}} {{selectedBaggage.price}}€</div>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                            v-model="selectedBaggage"
                            :label="$t('baggage_add_label')"
                            :items="items"
                            item-text="text"
                            outlined
                            return-object
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="cancel()"
                    >
                    {{$t("options_btn_cancel")}}
                    </v-btn>

                    <v-btn
                        color="#127996"
                        text
                        @click="save()"
                    >
                        {{$t("options_btn_save")}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "luggageSelected",
    props: [ 'optionsLuggage'],
    data: () => ({
        modalLuggageClosed: true,
        selectedBaggage: null,
        items:[]
     
    }),
    created() {
        if (this.optionsLuggage.checked_Add > 0) {
            const foundItem = this.items.find(item => item.number === this.optionsLuggage.checked_Add);
            if (foundItem) {
                this.selectedBaggage = foundItem;
            }
        }
        this.items = [
            {text: this.$t('item1_text'), price: 60, number: 1},
            {text: this.$t('item2_text'), price: 120, number: 2},
            {text: this.$t('item3_text'), price: 175, number: 3},
            {text: this.$t('item4_text'), price: 230, number: 4},
            {text: this.$t('item5_text'), price: 285, number: 5},
            {text: this.$t('item6_text'), price: 340, number: 6},
        ]
    },
    methods: {
        save(){
            const updatedOptionsLuggage = {
                ...this.optionsLuggage,
                checked: this.optionsLuggage.checked ,
                checked_Add: this.selectedBaggage ? this.selectedBaggage.number : 0,
                price: this.selectedBaggage ? this.selectedBaggage.price : 0,
            };

            this.$emit("modalLuggageClosed", updatedOptionsLuggage);
        },
        cancel(){
            this.$emit("modalLuggageClosed")
        },

    },
}
</script>

<style scoped>

</style>
