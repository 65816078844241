<template>
    <v-dialog
        v-model="showDialog"
        scrollable
        width="500px"
        height="500px"
        persistent
    >
        <v-card>
            <v-card-title class="background-dark-blue text-white">
                {{$t("options_btn_text")}}
            </v-card-title>

            <v-card-text style="height: 300px;" v-if="simulation != 'worldFlight'" >
                <template v-for="option in options">
                    <v-checkbox
                        v-model="selectedOptions"
                        :label="option.name"
                        :value="option.id"
                        :key="option.id"
                        color="#127996"
                        hide-details
                    ></v-checkbox>
                </template>
            </v-card-text>

            <v-card-text class="my-2" style="height: 570px;" v-if="simulation == 'worldFlight'">
                 <v-card class="mx-auto my-2" color="#f6f6f6" width="450" height="100" elevation="2" @click="optionsFlight.flex_package == true ? showDialogPlace = true : null" :class="{'disabled-card': optionsFlight.flex_package == false}">
                    <v-row no-gutters>
                        <v-col  cols="4">
                            <v-img
                                src="https://wwws.airfrance.fr/assets/ancillaries/push-cards/AF-seats-pushcard.png"
                                alt="Image"
                                height="80px"  
                                width="80px"   
                                class="pa-2 ml-2 mt-2"
                            ></v-img>
                        </v-col>

                        <v-col cols="6" class="d-flex align-center">
                        <div>
                            <div class="font-weight-bold">{{$t('seat_selection_title')}}</div>
                            <div v-if="placeSelected && optionsFlight.flex_package == true " class="subtitle-2 grey--text">{{$t('seat_selection_subtitle.selected')}} {{placeSelected}}</div>
                            <div v-else-if="optionsFlight.flex_package == false">{{$t('wrong_package')}}</div>
                            <div v-else class="subtitle-2 grey--text">{{$t('seat_selection_subtitle.choose')}}</div>
                        </div>
                        </v-col>

                        <v-col cols="2" class="d-flex align-center justify-center">
                        <v-icon>mdi-chevron-right</v-icon>
                        </v-col>
                    </v-row>
                </v-card>
                 <v-card class="mx-auto my-2" color="#f6f6f6" width="450" height="100" elevation="2" @click="showDialogLuggage = true">
                    <v-row no-gutters>
                        <v-col  cols="4">
                            <v-img
                                src="https://wwws.airfrance.fr/assets/ancillaries/push-cards/AF-baggage-pushcard.png"
                                alt="Image"
                                height="80px"  
                                width="80px"   
                                class="pa-2 ml-2 mt-2"
                            ></v-img>
                        </v-col>

                        <v-col cols="6" class="d-flex align-center">
                        <div>
                            <div class="font-weight-bold">{{$t('luggage_selection_title')}}</div>
                            <div v-if="optionsLuggage" class="subtitle-2 grey--text">{{optionsLuggage.checked + optionsLuggage.checked_Add}} {{$t('luggage_selection_subtitle')}}</div>
                        </div>
                        </v-col>

                        <v-col cols="2" class="d-flex align-center justify-center">
                        <v-icon>mdi-chevron-right</v-icon>
                        </v-col>
                    </v-row>
                </v-card>
                 <v-card class="mx-auto my-2" color="#f6f6f6" width="450" height="100" elevation="2">
                    <v-row no-gutters>
                        <v-col  cols="4">
                            <v-img
                                src="https://wwws.airfrance.fr/assets/ancillaries/push-cards/AF-saf-pushcard.png"
                                alt="Image"
                                height="80px"  
                                width="80px"   
                                class="pa-2 ml-2 mt-2"
                            ></v-img>
                        </v-col>

                        <v-col cols="6" class="d-flex align-center">
                        <div>
                            <div class="font-weight-bold">{{$t('sustainable_fuel_title')}}</div>
                            <v-alert
                                icon="mdi-information"
                                color="red"
                                compact
                                text="white"
                            >
                            <div class="text-caption">{{$t('alert_unavaible')}}</div>
                            </v-alert>
                        </div>
                        </v-col>

                        <v-col cols="2" class="d-flex align-center justify-center">
                        <v-icon>mdi-chevron-right</v-icon>
                        </v-col>
                    </v-row>
                </v-card>
                 <v-card class="mx-auto my-2" color="#f6f6f6" width="450" height="100" elevation="2">
                    <v-row no-gutters>
                        <v-col  cols="4">
                            <v-img
                                src="https://wwws.airfrance.fr/assets/ancillaries/push-cards/AF-lounge-pushcard.png"
                                alt="Image"
                                height="80px"  
                                width="80px"   
                                class="pa-2 ml-2 mt-2"
                            ></v-img>
                        </v-col>

                        <v-col cols="6" class="d-flex align-center">
                        <div>
                            <div class="font-weight-bold">{{$t('lounge_access_title')}}</div>
                            <v-alert
                                icon="mdi-information"
                                color="red"
                                compact
                                text="white"
                            >
                            <div class="text-caption">{{$t('alert_unavaible')}}</div>
                            </v-alert>
                        </div>
                        </v-col>

                        <v-col cols="2" class="d-flex align-center justify-center">
                        <v-icon>mdi-chevron-right</v-icon>
                        </v-col>
                    </v-row>
                </v-card>
                 <v-card class="mx-auto my-2" color="#f6f6f6" width="450" height="100" elevation="2">
                    <v-row no-gutters>
                        <v-col  cols="4">
                            <v-img
                                src="https://wwws.airfrance.fr/assets/ancillaries/push-cards/AF-conciergerie-pushcard.png"
                                alt="Image"
                                height="80px"  
                                width="80px"   
                                class="pa-2 ml-2 mt-2"
                            ></v-img>
                        </v-col>

                        <v-col cols="6" class="d-flex align-center">
                        <div>
                            <div class="font-weight-bold">{{$t('concierge_service_title')}}</div>
                            <v-alert
                                icon="mdi-information"
                                color="red"
                                compact
                                text="white"
                            >
                            <div class="text-caption">{{$t('alert_unavaible')}}</div>
                            </v-alert>
                        </div>
                        </v-col>

                        <v-col cols="2" class="d-flex align-center justify-center">
                        <v-icon>mdi-chevron-right</v-icon>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="cancel()"
                >
                   {{$t("options_btn_cancel")}}
                </v-btn>

                <v-btn
                    color="#127996"
                    text
                    @click="save()"
                >
                    {{$t("options_btn_save")}}
                </v-btn>
            </v-card-actions>
        </v-card>
        <SeatPlaneSelecting v-if="showDialogPlace" :optionsFlight="optionsFlight" @modalClosed="modalClosed"  />
        <LuggageSelected v-if="showDialogLuggage" :optionsLuggage="optionsLuggage"  @modalLuggageClosed="modalLuggageClosed"  />
    </v-dialog>
</template>

<script>
import SeatPlaneSelecting from "@/components/SeatPlaneSelecting";
import LuggageSelected from "@/components/LuggageSelected";
export default {
    name: "ManageOptionModal",
    components: {SeatPlaneSelecting, LuggageSelected},
    props: ["currentOptions", "simulation", "optionsFlight"],
    data: () => ({
        showDialog: true,
        options: [],
        optionsTrain: [],
        optionsBoat: [],
        optionsCar: [],
        selectedOptions: [],
        placeSelected: null,
        optionsLuggage: {
                cabin: 0,
                checked : 0,
                checked_Add: 0,
                price: 0
            },
        showDialogPlace: false,
        showDialogLuggage: false,
    }),
    created() {

        if (this.currentOptions) {
                this.selectedOptions = this.currentOptions.map(element => element.id);
            }
        if (this.optionsFlight) {
            console.log('optFlight',this.optionsFlight);
            this.placeSelected = this.optionsFlight.seat || null;
            if (this.optionsFlight.luggage) {
                this.optionsLuggage = { 
                    cabin: this.optionsFlight.luggage.cabin || 0,
                    checked: this.optionsFlight.luggage.checked || 0 ,
                    checked_Add: this.optionsFlight.luggage.checked_Add || 0 
                };
            }
        }
        this.optionsTrain =[{name: this.$t("option_train1"),id: 1},{name: this.$t("option_train2"),id: 2},{name: this.$t("option_train3"),id: 3},{name: this.$t("option_train4"),id: 4},{name: this.$t("option_train5"),id: 5}]
        this.optionsBoat=[{name: this.$t("option_boat1"),id: 1},{name: this.$t("option_boat2"),id: 2},{name: this.$t("option_boat3"),id: 3},{name: this.$t("option_boat4"),id: 4},{name: this.$t("option_boat5"),id: 5}]
        this.optionsCar=[{name: this.$t("option_car1"),id: 1}, {name: this.$t("option_car2"),id: 2},{name: this.$t("option_car3"),id: 3},{name: this.$t("option_car4"),id: 4},{name: this.$t("option_car5"),id: 5}]
        this.loadOptions()

    },
    methods:{
        loadOptions(){
            switch (this.simulation) {
                case "worldTrain":
                    this.options = this.optionsTrain
                    break;
                case "worldCar":
                    this.options = this.optionsCar
                    break;
                case "worldBoat":
                    this.options = this.optionsBoat
                    break;
        
                default:
                    break;
            }
        },
        save(){
            if(this.simulation == 'worldFlight'){
                 let options = {
                    placeSelected: this.placeSelected,
                    optionsLuggage: this.optionsLuggage
                };
                this.$emit("optionModalClosed", options)
            }else{
                let optionsSelected = this.selectedOptions.map(id => {
                    let element = this.options.find(item => item.id === id);
                    return element ? element : null;
                });
                this.$emit("optionModalClosed", optionsSelected)
            }
        },
        modalClosed(data){
            if(data != null){
                this.placeSelected = data
                console.log("placeSelected",this.placeSelected)
            }
            this.showDialogPlace = false
        },
        modalLuggageClosed(data){
            if(data != null){
                console.log('data',data);
                this.optionsLuggage.checked = data.checked
                this.optionsLuggage.checked_Add = data.checked_Add
                this.optionsLuggage.price = data.price
            }
            console.log('check',this.optionsLuggage.checked);
            this.showDialogLuggage = false
        },
        cancel(){
            this.$emit("optionModalClosed")
        }
    }
}
</script>

<style scoped>
.disabled-card {
    pointer-events: none;
    opacity: 0.6;
}
</style>
