<template>
  <div class="app-bar">
      <v-app-bar
          app
          color="primary"
          flat>
                <v-menu offset-y >
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="d-flex">
                                <v-img height="80" width="324" :src="selectedItem.image" :alt="selectedItem.text" contain />
                                <v-icon>mdi-chevron-down</v-icon>
                            </span>
                        </template>
                        
                        <v-list class="menu">
                             <v-row class="d-flex align-center justify-center mb-1">
                                <v-col
                                    v-for="(item, i) in items"
                                    :key="i"
                                    :align="item.align"
                                    cols="6"
                                    md="6"
                                >   
                                    
                                    <v-btn
                                        width="150"
                                        height="100"
                                        :disabled="selectedItem.value == item.value"
                                        color="primary"
                                        @click="changeSimulation(item.value)"
                                    >   
                                    <span>
                                        <v-icon size="20">
                                            {{item.icon}}
                                        </v-icon>
                                        <p class="text-button">{{ item.text }}</p> 
                                    </span>
                                    </v-btn>
                                    
                                </v-col>
                            </v-row>
                        </v-list>
                    </v-menu>


          <v-tabs
              centered
              class="ml-n9"
              color="#8de8fe"
          >
              <v-tab href="#">
                  <img :src="require('@/assets/img/ic_travel.svg')" class="header_link_logo">
                  <span class="header_link activated">{{$t("appBar_title1")}}</span>
              </v-tab>
              <v-tab >
                  <img :src="require('@/assets/img/ic_qrcode.svg')" class="header_link_logo">
                  <span class="header_link">{{$t("appBar_title2")}}</span>
              </v-tab>
              <v-tab href="#">
                  <img :src="require('@/assets/img/ic_offers.svg')" class="header_link_logo">
                  <span class="header_link">{{$t("appBar_title3")}}</span>
              </v-tab>
              <v-tab href="#">
                  <img :src="require('@/assets/img/ic_account.svg')" class="header_link_logo">
                  <span class="header_link">{{$t("appBar_title4")}}</span>
              </v-tab>
          </v-tabs>
          
          <div class=" d-flex align-center justify-center locales mr-6">
            <!-- <span class="align-self-center text-overline font-weight-medium">{{$t($i18n.locale)}}</span> -->
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                        <v-avatar size="30">
                            <v-img  v-if="$t($i18n.locale) == 'English'" :src="require('@/assets/img/english.png')" alt="English"></v-img>
                            <v-img  v-if="$t($i18n.locale)== 'Français'" :src="require('@/assets/img/france.png')" alt="Français"></v-img>
                        </v-avatar>
                    </span>
                </template>
                <v-list>
                    <v-list-item v-for="(locale, index) in locales" :key="`locale-${index}`" class="pa-0">
                        <v-btn text x-small @click="changeLang(locale)">
                            <v-list-item-title class="text-overline">{{ $t(locale) }}</v-list-item-title>
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

          <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      class="me-4"
                      @click="reload()">mdi-reload</v-icon>
              </template>
              <span>{{$t("appBar_tooltip_reload")}}</span>
          </v-tooltip>

      </v-app-bar>
  </div>
</template>

<script>
var MQTT = require("@/assets/js/MqttService");

export default {
    name: 'AppBar',
    data() {
        return {
            items: [
                { value: 1, image: require("@/assets/img/worldFlight.png"),text: "WORLDFLIGHT", icon: "mdi-airplane", align: 'end'},
                { value: 2, image: require("@/assets/img/worldTrain.png"),text: "WORLDTRAIN", icon: "mdi-train", align: 'start'},
                { value: 3, image: require("@/assets/img/worldCar.png"),text: "WORLDCAR", icon: "mdi-car", align: 'end'},
                { value: 4, image: require("@/assets/img/worldBoat.png"),text: "WORLDBOAT", icon: "mdi-ferry", align: 'start'},
            ],
            
            selectedItem: { value: null, 
                image: null,
                text: null, 
                icon: null, 
                align: null,
            },
        }
    },
    created() {
        this.loadSimulation()
    },
    computed: {
        locales() {
            return this.$i18n.availableLocales
        }
    },
    methods:{
        loadSimulation(routeName){
            let simulation
            if (routeName) {
                simulation = routeName
            }else{
                simulation = this.$route.name
            }

            switch (simulation) {
                case "worldFlight":
                    this.selectedItem.value = 1
                    this.selectedItem.image = require("@/assets/img/worldFlight.png")
                    this.selectedItem.text = "WORLDFLIGHT"
                    this.selectedItem.icon = "mdi-airplane"
                    this.selectedItem.align = 'end'
                    break
                case "worldTrain":
                    this.selectedItem.value = 2
                    this.selectedItem.image = require("@/assets/img/worldTrain.png")
                    this.selectedItem.text = "WORLDTRAIN"
                    this.selectedItem.icon = "mdi-train"
                    this.selectedItem.align = 'start'
                    break
                case "worldCar":
                    this.selectedItem.value = 3
                    this.selectedItem.image = require("@/assets/img/worldCar.png")
                    this.selectedItem.text = "WORLDCAR"
                    this.selectedItem.icon = "mdi-car"
                    this.selectedItem.align = 'end'
                    break;
                case "worldBoat":
                    this.selectedItem.value = 4
                    this.selectedItem.image = require("@/assets/img/worldBoat.png")
                    this.selectedItem.text = "WORLDBOAT"
                    this.selectedItem.icon = "mdi-ferry"
                    this.selectedItem.align = 'start'
                    break
        
                default:
                    break;
            }
        },
        changeSimulation(value) {
            let routeName = '';
            switch (value) {
                case 1:
                    routeName = 'worldFlight';
                    break;
                case 2:
                    routeName = 'worldTrain';
                    break;
                case 3:
                    routeName = 'worldCar';
                    break;
                case 4:
                    routeName = 'worldBoat';
                    break;
                default:
                    break;
            }
            if (this.$route.name !== routeName) {
                this.$router.push({ name: routeName });
                localStorage.setItem("WORLD_TRAVEL_SIMULATION", routeName);
                console.log("DLN", "je passe ici")
                MQTT.publishMessage('SEND_SIMULATION', routeName);
                this.loadSimulation(routeName)
            }
        },
        changeLang(locale){
                this.$i18n.locale = locale
                localStorage.setItem("WORLD_TRAVEL_LANG", locale);
            },
        sendFakeData(){
            let data = {
                "id": 1,
                "startingStation": "Lille Europe",
                "destinationStation": "Lyon Part-Dieu",
                "date": "27/09/2023",
                "hour": "19:12 - 00:59",
                "price": 186,
                "train": "Train TGV 3925",
                "options": [],
                "alreadySent": false
            }
            MQTT.publishMessage("SEND_PRODUCTS", JSON.stringify(data))
        },
        reload(){
            MQTT.publishMessage("SEND_END_SIMULATION", "", () => {
                // window.location.reload()
                this.$router.push({name: "home"})
                localStorage.removeItem("WORLD_TRAVEL_SIMULATION");
            })
        },
    },
}
</script>

<style scoped>
.v-icon.v-icon{
    color: rgb(255, 255, 255);
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    display: none;
}
.v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
    border: 2px solid currentColor;
    display: none;
}
.v-text-field .v-input__prepend-inner, .v-text-field .v-input__append-inner {
    align-self: inherit;
    display: inline-flex;
    margin-top: 4px;
    line-height: 1;
    -webkit-user-select: none;
    color: white;
    -moz-user-select: none;
    user-select: none;
    display: none;
}
#header_logo{
    width: 220px;
}

.header_link{
    color: white;
}

.activated{
    color: #8de8fe
}

.header_link_logo{
    width: 24px;
    height: 24px;
}

.menu{
    width: 350px;
    height: 244px;
}
</style>
