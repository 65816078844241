// var mqtt = require('precompiled-mqtt');
import { Buffer } from 'buffer';
import process from 'process';
import EventEmitter from 'eventemitter3';

window.Buffer = Buffer;
window.process = process;
const mqtt = require('mqtt');

var mqttConf = {
    host: "mqtt.labs.worldline-solutions.com",
    port: 8003,
    username: "rd",
    password: "@t0s@t0s",
    rejectUnauthorized: false,
    protocol: 'wss'
}
var terminalId = null
const eventBus = new EventEmitter();

var baseTopic
var baseTopicRetain = `rd/chatbot/connectedKitchenBot/WORLDTRAVEL/`
var topicWaitingAll  = baseTopicRetain + "waiting/#"
var topicProducts 
var topicReady
var topicWaiting
var topicSendConnected
var topicPayment 
var topicLoyalty 
var topicUsingMiles
var topicScanLoyalty 
var topicSimulation 
var topicStart
var topicEnd 
var topicEndFromCashier 
var topicEndSimulation 

var client;

function initTopics(){
    if(terminalId){
        baseTopic = `rd/chatbot/connectedKitchenBot/WORLDTRAVEL/${terminalId}/`;
        topicWaiting = baseTopicRetain + `waiting/${terminalId}`
        topicProducts = baseTopic + "products";
        topicReady = baseTopic + "ready";
        topicPayment = baseTopic + "payment";
        topicLoyalty = baseTopic + "loyalty";
        topicScanLoyalty = baseTopic + "scan/loyalty";
        topicUsingMiles = baseTopic + "use/miles";
        topicSimulation = baseTopic + "simulation";
        topicStart = baseTopic + "start";
        topicEnd = baseTopic + "end";
        topicEndFromCashier = baseTopic + "send_end";
        topicEndSimulation = baseTopic + "send/end/simulation";
        topicSendConnected = baseTopic + "send/connected";
        return true
    }
    return false
}
function connectClient() {
    const URLMqtt = `${mqttConf.protocol}://${mqttConf.host}:${mqttConf.port}`;

    client = mqtt.connect(URLMqtt, {
        username: mqttConf.username,
        password: mqttConf.password,
        rejectUnauthorized: false
    });

    client.on('error', (err) => {
        console.error('Connection error: ', err);
        client.end();
    });

    client.on('connect', () => {
        console.log('Successfully connected to MQTT broker');
    });


    return client;
}
function publishMessage(type, message = '', callback = null) {
    if (initTopics()) {
        client = connectClient();

        client.on('connect', () => {
            const topic = getTopic(type);
            client.publish(topic, message, {}, callback);
        });
    }
}

function publishMessageRetain(type, message = '', callback = null) {
    if (initTopics()) {
        client = connectClient();

        client.on('connect', () => {
            const topic = getTopic(type);
            client.publish(topic, message, { retain: true }, callback);
        });

    }
}



function subscribeEnd(instance) {
    client = connectClient();
    client.subscribe(topicEnd)

    client.on('message', function () {
        var simulationActive = localStorage.getItem("WORLD_TRAVEL_SIMULATION");
        switch (simulationActive) {
            case 'worldFlight': {
                instance.reloadTravel();
                break;
            }
            case 'worldTrain': {
                instance.reloadTravel();
                break;
            }
            case 'worldCar': {
                instance.reloadTravel();
                break;
            }
            case 'worldBoat': {
                instance.reloadTravel();
                break;
            }
            default:
                break;
        }
    })
}

function subscribeTopics(callbacks, instance) {
    if (!client) {
        client = connectClient();
    }
    
    client.on('connect', () => {
        client.subscribe([topicScanLoyalty, topicUsingMiles, topicEnd]);

        client.on('message', (topic, message) => {
            const msgStr = message.toString();

            if (topic === topicScanLoyalty && callbacks.onScanLoyalty) {
                callbacks.onScanLoyalty(msgStr);
            }
            if (topic === topicUsingMiles && callbacks.onUseMiles) {
                callbacks.onUseMiles(msgStr);
            }

            if (topic === topicEnd) {
                var simulationActive = localStorage.getItem("WORLD_TRAVEL_SIMULATION");
                switch (simulationActive) {
                    case 'worldFlight': {
                        instance.reloadTravel();
                        break;
                    }
                    case 'worldTrain': {
                        instance.reloadTravel();
                        break;
                    }
                    case 'worldCar': {
                        instance.reloadTravel();
                        break;
                    }
                    case 'worldBoat': {
                        instance.reloadTravel();
                        break;
                    }
                    default:
                        break;
                }
            }
        });
    });
}
function subscribeScanLoyalty(callback) {

    client = connectClient();
    
    client.on('connect', () => {

        client.subscribe(topicScanLoyalty)

        client.on('message', (topic, message) => {
            callback(message.toString());
        });
    });


}
function subscribeUseMiles(callback) {

    client = connectClient();
    
    client.on('connect', () => {

        client.subscribe(topicUsingMiles)

        client.on('message', (topic, message) => {
            callback(message.toString());
        });
    });


}

function subscribeRetainTerminalId(callback) {
    client = connectClient();

    client.on('connect', () => {
        console.log('Connected to broker');
        
        client.subscribe(topicWaitingAll)
      
        
        client.on('message', (topic, message) => {
            console.log(`Message received on topic ${topic}: ${message.toString()}`);
            const currentTime = Date.now();
            const retainedTimestamp = getRetainedTimestamp(message);
            const lastNumber = getLastNumberFromMessage(topic);
            if (isMoreThanHoursAgo(currentTime, retainedTimestamp, 12)) {
                client.publish(topic, '', { retain: true });
            } else {
                callback(lastNumber);
            }
        });
    });

}

function unsubscribeScanLoyalty() {
    client = connectClient();

    client.on('connect', () => {
        client.unsubscribe(topicScanLoyalty, (err) => {
            if (err) console.error('Unsubscribe error: ', err);
        });
    });
}

function setTerminalId(id) {
    terminalId = id;
    eventBus.emit('terminalIdChanged', terminalId);
}

function getTerminalId() {
    return terminalId;
}

function getTopic(type) {
    var topic;
    switch (type) {
        case "SEND_START":
            topic = topicStart;
            break;
        case "SEND_END":
            topic = topicEndFromCashier;
            break;
        case "SEND_END_SIMULATION":
            topic = topicEndSimulation;
            break;
        case "SEND_PRODUCTS":
            topic = topicProducts;
            break;
        case "SEND_PAYMENT":
            topic = topicPayment;
            break;
        case "SEND_LOYALTY":
            topic = topicLoyalty;
            break;
        case "SCAN_LOYALTY":
            topic = topicScanLoyalty;
            break;
        case "USE_MILES":
            topic = topicUsingMiles;
            break;
        case "SEND_SIMULATION":
            topic = topicSimulation;
            break;
        case "END":
            topic = topicEnd;
            break;
        case "READY":
            topic = topicReady;
            break;
        case "WAITING_ALL":
            topic = topicWaitingAll;
            break;
        case "WAITING":
            topic = topicWaiting;
            break;
        case "SEND_CONNECTED":
            topic = topicSendConnected;
            break;
    }
    return topic;
}

function getRetainedTimestamp(message) {
    return parseInt(message.toString(), 10);
}

function isMoreThanHoursAgo(currentTime, timestampMessageRetain, hours) {
    const hoursInMillis = hours * 60 * 60 * 1000;
    const timeDifference = currentTime - timestampMessageRetain;
    return timeDifference > hoursInMillis;
}

function getLastNumberFromMessage(topic) {
    return topic.slice(-4);
}

export {
    publishMessage,
    publishMessageRetain,
    subscribeScanLoyalty,
    subscribeUseMiles,
    subscribeRetainTerminalId,
    subscribeEnd,
    subscribeTopics,
    unsubscribeScanLoyalty,
    setTerminalId,
    getTerminalId,
    eventBus
};