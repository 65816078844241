<template>
    <div class="product pa-4">

        <v-menu
            v-model="showDatePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="currentTravel.date"
                    :label="$t('product_date')"
                    prepend-icon="mdi-calendar"
                    class="pt-3"
                    readonly
                    dense
                    dark
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </template>

            <v-date-picker
                :value="parseDate"
                color="#127996"
                elevation="15"
                show-current
                :min="tomorrow"
                @input="showDatePicker = false"
                @change="setDate"
                locale="fr"
                first-day-of-week="1"
            ></v-date-picker>
        </v-menu>


        <v-container v-if="simulation == 'worldCar'">
            <v-row>
                <v-col cols="6" class="ps-0 pb-0">
                    <v-select
                        v-model="currentTravel.startingStation"
                        :items="trainStationsStart"
                        :label="$t('product_collect_start')"
                        hide-details
                        outlined
                        dense
                        dark
                    ></v-select>
                </v-col>

                <v-col cols="6" class="pe-0 pb-0">
                    <v-select
                        v-model="currentTravel.destinationStation"
                        :items="trainStationsEnd"
                        :label="$t('product_collect_end')"
                        hide-details
                        outlined
                        dense
                        dark
                    ></v-select>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else>
            <v-row>
                <v-col cols="6" class="ps-0 pb-0">
                    <v-select
                        v-model="currentTravel.startingStation"
                        :items="trainStationsStart"
                        :label="$t('product_start')"
                        hide-details
                        outlined
                        dense
                        dark
                    ></v-select>
                </v-col>

                <v-col cols="6" class="pe-0 pb-0">
                    <v-select
                        v-model="currentTravel.destinationStation"
                        :items="trainStationsEnd"
                        :label="$t('product_destination')"
                        hide-details
                        outlined
                        dense
                        dark
                    ></v-select>
                </v-col>
            </v-row>
        </v-container>

        <div v-if="generatedTrains.length > 0" class="d-flex justify-space-around">
            <div v-for="train in generatedTrains" :key="'train_' + train.id" :ref="'train_' + train.id" class="train-item d-flex flex-column mt-4 px-4 py-2 text-center" @click="changeTrain(train)">
                <span class="font-weight-bold">{{train.name}}</span>
                <span v-if="simulation == 'worldCar'">{{train.km}} {{$t("product_text_km")}}</span>
                <span >{{train.hour}}</span>
                <span v-if="simulation != 'worldCar'">{{train.price}}€</span>
                <span v-else>{{train.price}}{{$t("product_text_price_by_day")}}</span>
            </div>
        </div>

        <div class="d-flex mt-4" v-if="trainSelected && simulation == 'worldFlight' ">
            <v-card v-for="(items,i) in packages" :key="i" class="mx-2 d-flex flex-column" :class="{ 'selected-card': selectedPackage === items.title }" max-width="300" min-height="350px" @click="selectPack(items.title)" >
                <v-card-title class="text-h6 d-flex justify-center font-weight-bold background-dark-blue text-white">{{items.title}}</v-card-title>
                <v-card-text class="mt-4">
                    <div class="text-text-subtitle-1 font-weight-bold mb-2">{{ $t('product_included_per_passenger') }}</div>
                    <v-row class="d-flex flex-column">
                        <v-col>
                            <v-icon v-if="items.option1.check" left color="green">
                            mdi-check
                            </v-icon>
                            <v-icon v-else left color="red">
                            mdi-close
                            </v-icon>
                            <span>{{items.option1.text}}</span>
                        </v-col>
                        <v-col>
                            <v-icon v-if="items.option2.check" left color="green">
                            mdi-check
                            </v-icon>
                            <v-icon v-else left color="red">
                            mdi-close
                            </v-icon>
                            <span>{{items.option2.text}}</span>
                        </v-col>
                        <v-col>
                            <v-icon v-if="items.option3.check" left color="green">
                            mdi-check
                            </v-icon>
                            <v-icon v-else left color="red">
                            mdi-close
                            </v-icon>
                            <span>{{items.option3.text}}</span>
                        </v-col>
                        <v-col>
                            <v-icon v-if="items.option4.check" left color="green">
                            mdi-check
                            </v-icon>
                            <v-icon v-else left color="red">
                            mdi-close
                            </v-icon>
                            <span>{{items.option4.text}}</span>
                        </v-col>
                        <v-col>
                            <v-icon v-if="items.option5.check" left color="green">
                            mdi-check
                            </v-icon>
                            <v-icon v-else left color="red">
                            mdi-close
                            </v-icon>
                            <span>{{items.option5.text}}</span>
                        </v-col>
                        
                    </v-row>
                    
                </v-card-text>
                <v-spacer></v-spacer>
                <div class="d-flex justify-center my-2">
                    <div class="text-h4 secondary--text">{{ items.price }} EUR</div>
                </div>
            </v-card>

        </div>

        <div v-if="currentTravel.options.length > 0" class="mt-6">
            <span class="text-white text-button" v-if="currentTravel.options.length == 1">{{$t("product_text_option_selected")}}</span>
            <span class="text-white text-h6" v-else>{{$t("product_text_option_selected")}}</span>

            <span class="text-white font-italic" v-for="(option, index) in currentTravel.options" :key="option.id">
                {{option.name}}
                <span v-if="index < currentTravel.options.length - 1" class="font-weight-black"> | </span>
            </span>
        </div>
        <div>

            <div class="d-flex justify-end mt-4" v-if="(simulation !== 'worldFlight' && trainSelected) || (simulation === 'worldFlight' && selectedPackage)">
                <v-btn @click="showManageOptionModal = true" class="me-4">
                    <template v-if="currentTravel.options.length === 0">
                        {{$t("product_btn_add_options")}}
                    </template>
                    <template v-else>
                        {{$t("product_btn_update_options")}}
                    </template>
                </v-btn>

                <v-btn @click="sendProducts()">
                    <template v-if="!currentTravel.alreadySent">
                        {{$t("product_btn_validate_travel")}}
                    </template>
                    <template v-else>
                        {{$t("product_btn_update_travel")}}
                    </template>
                </v-btn>
            </div>
        </div>

        <ManageOptionModal :simulation="simulation" :optionsFlight="optionsFlight" :currentOptions="currentTravel.options" v-if="showManageOptionModal" @optionModalClosed="optionModalClosed" />

    </div>
</template>

<script>
/* eslint-disable */
import ManageOptionModal from "@/components/ManageOptionModal";
var MQTT = require("@/assets/js/MqttService");

export default {
    name: "Product",
    components: {ManageOptionModal},
    props: ['travel', 'station', "train",'simulation'],
    data: () => ({
        showDatePicker: false,
        tomorrow: null,
        currentTravel: null,
        trainSelected: false,
        priceTrainSelected: 430,
        generatedTrains: [],
        showManageOptionModal: false,
        selectedPackage: null,
        optionsFlight: {
            seat: null,
            flex_package: false,
            luggage: {
                cabin: 1,
                checked : 0,
                checked_Add: 0
            }
        },
        packages:[]
    }),
    created() {
        this.currentTravel = JSON.parse(JSON.stringify(this.travel))
        this.tomorrow = this.$moment().add(1, 'day').toISOString()
    },
    computed:{
        trainStationsStart(){
            if(this.currentTravel.destinationStation == null){
                return this.station.filter(station => station != this.currentTravel.destinationStation)
            }
            return this.station
        },
        trainStationsEnd(){
            if(this.currentTravel.startingStation != null){
                return this.station.filter(station => station != this.currentTravel.startingStation)
            }
            return this.station
        },
        parseDate(){
            if(this.currentTravel.date == null){
                return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            }else{
                return this.$moment(this.currentTravel).format("YYYY-MM-DD")
            }
        },
    },
    methods: {
        sendProducts(){
            if (this.currentTravel.options.length > 0 && this.simulation !== "worldFlight") {
                let copyProduct = {...this.currentTravel}
                let nameOptions = this.currentTravel.options.map(element => element.name);
                copyProduct.options = nameOptions
                MQTT.publishMessage("SEND_PRODUCTS", JSON.stringify(copyProduct))
            }else if( this.simulation == "worldFlight" ){
                if (this.optionsFlight.seat != null) {
                    this.currentTravel.place = this.optionsFlight.seat
                }else{
                    this.currentTravel.place = "1G"
                }
                let copyProduct = {...this.currentTravel}
                let nameOptions = this.currentTravel.options.map(element => element.name);
                copyProduct.options = nameOptions
                MQTT.publishMessage("SEND_PRODUCTS", JSON.stringify(copyProduct))
            }else{
                MQTT.publishMessage("SEND_PRODUCTS", JSON.stringify(this.currentTravel))
            }
            this.currentTravel.alreadySent = true
            this.$emit("sendProduct")
        },
        setDate(value){
            this.currentTravel.date = this.$moment(value, "YYYY-MM-DD").format("DD/MM/yyyy")
        },
    
        generateTrains(){
            this.generatedTrains = []

            let randomIndexOrderAsc = []
            if (this.simulation != "worldCar") {
                
                while (randomIndexOrderAsc.length < 3) {
                    let index = Math.floor(Math.random() * this.train.hour.length);
                    if (!randomIndexOrderAsc.includes(index)) {
                        randomIndexOrderAsc.push(index);
                    }
                }
                randomIndexOrderAsc.sort((a, b) => a - b);
    
                let tempHours = randomIndexOrderAsc.map((index) => {
                    return this.train.hour[index];
                });
    
                for(let i = 0; i < 3; i++){
                    let train = {
                        id: i + 1,
                        name: this.getRandomVehicle(),
                        hour: tempHours[i],
                        price: this.getRandomPrice(),
                    }
                    this.generatedTrains.push(train)
                }
            }else{
                 while (randomIndexOrderAsc.length < 3) {
                    let index = Math.floor(Math.random() * this.train.km.length);
                    if (!randomIndexOrderAsc.includes(index)) {
                        randomIndexOrderAsc.push(index);
                    }
                }
                randomIndexOrderAsc.sort((a, b) => a - b);
    
                let tempHours = randomIndexOrderAsc.map((index) => {
                    return this.train.hour[index];
                });
                let tempkm = randomIndexOrderAsc.map((index) => {
                    return this.train.km[index];
                });
    
                for(let i = 0; i < 3; i++){
                    let train = {
                        id: i + 1,
                        name: this.getRandomVehicle(),
                        hour:tempHours[i],
                        km: tempkm[i],
                        price: this.getRandomPrice(),
                    }
                    this.generatedTrains.push(train)
                }
            }

            if(this.trainSelected){
                this.$refs.train_1[0].classList.remove("selected")
                this.$refs.train_2[0].classList.remove("selected")
                this.$refs.train_3[0].classList.remove("selected")
            }
        },
        getRandomVehicle(){
            let type = this.train.type[Math.floor(Math.random() * this.train.type.length)]
            let number = this.train.number[Math.floor(Math.random() * this.train.number.length)]
            let nameVehicle
            switch (this.simulation) {
                case "worldFlight":
                    nameVehicle = this.$t("product_vehicle_flight")
                    return nameVehicle + " " + type + " " + number
                case "worldTrain":
                    nameVehicle = this.$t("product_vehicle_train")
                    return nameVehicle + " " + type + " " + number
                case "worldCar":
                    nameVehicle = this.$t("product_vehicle_car")
                    return nameVehicle + " " + type 
                case "worldBoat":
                    nameVehicle = this.$t("product_vehicle_boat")
                    return nameVehicle + " " + type + " " + number + " Jours"
        
                default:
                    break;
            }
        },
        getRandomPrice(){
            return this.train.price[Math.floor(Math.random() * this.train.price.length)]
        },
        changeTrain(train){
            this.$refs.train_1[0].classList.remove("selected")
            this.$refs.train_2[0].classList.remove("selected")
            this.$refs.train_3[0].classList.remove("selected")

            this.$refs['train_' + train.id][0].classList.add("selected");

            this.currentTravel.train = train.name
            this.currentTravel.hour = train.hour
            if (this.simulation == "worldCar") {
                this.currentTravel.km = train.km
            }
            this.currentTravel.price = train.price
            console.log("seat place",this.optionsFlight.seat);
            if (this.optionsFlight.seat != null) {
                this.currentTravel.place = this.optionsFlight.seat
            }else{
                this.currentTravel.place = "1G"
            }
            this.trainSelected = true

            this.priceTrainSelected = train.price
            this.packages = [
            {
                id: 1,
                title: this.$t('package1_title'),
                option1: { text: this.$t('package1_opt1'), check: true },
                option2: { text: this.$t('package1_opt2'), check: false },
                option3: { text: this.$t('package1_opt3'), check: false },
                option4: { text: this.$t('package1_opt4'), check: false },
                option5: { text: this.$t('package1_opt5'), check: false },
                price: this.priceTrainSelected,
            },
            {
                id: 2,
                title: this.$t('package2_title'),
                option1: { text: this.$t('package2_opt1'), check: true },
                option2: { text: this.$t('package2_opt2'), check: true },
                option3: { text: this.$t('package2_opt3'), check: false },
                option4: { text: this.$t('package2_opt4'), check: true },
                option5: { text: this.$t('package2_opt5'), check: false },
                price: 475,
            },
            {
                id: 3,
                title: this.$t('package3_title'),
                option1: { text: this.$t('package3_opt1'), check: true },
                option2: { text: this.$t('package3_opt2'), check: true },
                option3: { text: this.$t('package3_opt3'), check: true },
                option4: { text: this.$t('package3_opt4'), check: true },
                option5: { text: this.$t('package3_opt5'), check: false },
                price: 491,
            },
            {
                id: 4,
                title: this.$t('package4_title'),
                option1: { text: this.$t('package4_opt1'), check: true },
                option2: { text: this.$t('package4_opt2'), check: true },
                option3: { text: this.$t('package4_opt3'), check: true },
                option4: { text: this.$t('package4_opt4'), check: true },
                option5: { text: this.$t('package4_opt5'), check: true },
                seatSelection: { id: 'seatSelection', text: this.$t('package4_seatSelection'), check: true },
                price: 567,
            }
        ];
            
        },
        optionModalClosed(data){
            
            if (this.simulation !== "worldFlight") {
                if (data) {
                    this.currentTravel.options = data;
                }
            } else {
                if (data) {

                    const selectedPackage = this.packages.find(pkg => pkg.title === this.selectedPackage);
                    if (selectedPackage) {
                        this.currentTravel.price = selectedPackage.price;
                    }

                    if (data.optionsLuggage.price) {
                        this.currentTravel.price += data.optionsLuggage.price;
                    }

                    this.currentTravel.selectedSeat = data.placeSelected;
                    this.optionsFlight.luggage.checked = data.optionsLuggage.checked
                    this.optionsFlight.luggage.checked_Add = data.optionsLuggage.checked_Add

                    console.log('data',data);
                    if (data.placeSelected) {
                        this.optionsFlight.seat = data.placeSelected
                    }
                }

                const selectedSeat = this.currentTravel.selectedSeat || this.$t('no_seat');
                if (Array.isArray(this.currentTravel.options)) {
                    this.currentTravel.options.forEach(option => {
                        if (option.id === 'seatSelection') {
                            option.name = `${this.$t('selected_seat')} ${selectedSeat})`;
                        }
                    });
                }
            
            }
            console.log('closed');
            this.showManageOptionModal = false
        },
        selectPack(title) {
            this.selectedPackage = title; 

            const selectedPackage = this.packages.find(pkg => pkg.title === title);

            this.currentTravel.options = [];

            if (selectedPackage) {
                Object.keys(selectedPackage).forEach((key) => {
                    if (selectedPackage[key].check && key !== 'seatSelection') {
                        this.currentTravel.options.push({
                            name: selectedPackage[key].text,
                            id: key
                        });
                    }
                });

            const option3Index = this.currentTravel.options.findIndex(option => option.id === 'option3');
            if (option3Index > -1) {
                this.currentTravel.options.splice(option3Index, 1);
            }

            if (selectedPackage.seatSelection && selectedPackage.seatSelection.check) {
                const selectedSeat = this.currentTravel.selectedSeat || this.$t('no_seat');
                this.currentTravel.options.push({
                    name: `${selectedPackage.seatSelection.text} ${this.$t('selected_seat2')} ${selectedSeat})`,
                    id: selectedPackage.seatSelection.id
                });
            }
            this.currentTravel.price = selectedPackage.price;
        }

        },
    },
    watch:{
         selectedPackage(newValue) {
            const selectedPackageObj = this.packages.find(pkg => pkg.title === newValue);
            if (selectedPackageObj && selectedPackageObj.id !== 1) {
                this.optionsFlight.luggage.checked = 1;
            } else {
                this.optionsFlight.luggage.checked = 0;  
            }
            if (selectedPackageObj && selectedPackageObj.id !== 4) {
                this.optionsFlight.flex_package = false;
            } else {
                this.optionsFlight.flex_package = true;  
            }
        },
        currentTravel: {
            handler(val){
                this.$emit("updateTravel", val)
            },
            deep: true
        },
        priceTrainSelected(newValue) {
            this.priceTrainSelected = newValue
        },
        "currentTravel.date": function (newValue, OldValue) {
            if(this.currentTravel.date != null && this.currentTravel.startingStation != null && this.currentTravel.destinationStation != null){
                this.generateTrains()
            }
        },
        "currentTravel.startingStation": function (newValue, OldValue) {
            if(this.currentTravel.date != null && this.currentTravel.startingStation != null && this.currentTravel.destinationStation != null){
                this.generateTrains()
            }
        },
        "currentTravel.destinationStation": function (newValue, OldValue) {
            if(this.currentTravel.date != null && this.currentTravel.startingStation != null && this.currentTravel.destinationStation != null){
                this.generateTrains()
            }
        }
    }
}
</script>

<style scoped>
.theme--dark>>>.v-label{
    color: white !important;
}

.theme--dark>>>.v-input__slot fieldset{
    color: white !important;
}

.v-list>>>.v-list-item--active{
    color: #0c131f !important;
}

.train-item{
    background-color: white;
    color: #0c131f;
    border-radius: 14px;
    width: fit-content;
    cursor: pointer;
}

.train-item.selected{
    background-color: #127996;
    color: white;
}
.selected-card {
  background-color: #e0f7fa !important; 
}

</style>
