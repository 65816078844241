import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#2D8C8C",
                secondary: "#46BEAA",
                blue_dark: "#127996",
                accent: "FFFFFF",
            },
        },
    },
    icons: {
        iconfont: 'mdi', 
      },
});
