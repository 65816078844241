<template>
  <div class="worldBoat align-stretch">

       <div class="first-column d-flex flex-column">
          <div class="d-flex flex-column pa-4 background-app">
              <div v-if="customerName" class="d-flex align-center justify-center">
                  <span class="text-white text-decoration-underline customer-name">{{customerName}}</span>
                  <a href="#"><v-icon small color="white" class="ms-2">mdi-information-slab-circle</v-icon></a>
              </div>
              <span class="text-white text-center mb-4" v-if="customerName">{{$t("trainView_points_prime")}}</span>
    
              <span class="text-white text-center font-weight-bold">{{$t("trainView_text_client")}}</span>
    
              <v-btn @click="handleStartStop()" class="mt-3">{{processStarted ? $t("trainView_btn_interrupt") : $t("trainView_btn_start")}} {{$t("trainView_btn_text_travel")}}</v-btn>
    
              <v-btn @click="sendLoyalty()" :disabled="processStarted == false" class="mt-3">{{$t("trainView_btn_scan_loyalty")}}</v-btn>
    
              <v-btn @click="sendPayment()" :disabled="processStarted == false" class="mt-3">{{$t("trainView_btn_checkout")}}</v-btn>
          </div>
          <div v-if="totalAmount > 0" class="my-2 d-flex pa-4 background-app">
            <p class="d-flex flex-column mb-0 text-h5 text-white font-weight-bold">
                <span>{{$t("trainView_text_total")}}</span>
                <span class="text-caption font-italic">{{$t("trainView_text_Microdon")}}</span> 
            </p>
            <p class="mb-0 mx-2 text-h5 text-white text-center font-weight-bold">{{formatPrice(totalAmount)}}</p>
          </div>
      </div>

      <div class="second-column ms-4" :class="{'background-app d-flex flex-column align-center justify-center': !processStarted}">
            <template v-if="processStarted">
              <template v-for="travel in travels">
                  <div class="background-app mb-4" :key="travel.id" v-if="travel.id !== 0">
                      <Product :travel="travel" :simulation="simulation" :station="stations" :train="vehicle" @updateTravel="updateTravel" @sendProduct="calculateTotalAmount()"/>
                  </div>
              </template>
    
              <div class="d-flex justify-end">
                <v-btn class="text-white" color="#127996" v-show="showAddNew" @click="addNewTravel">{{$t("trainView_btn_add_new_travel")}}</v-btn>
              </div>
            </template>
            <template v-else>
              <p class="mb-0 text-body-1 text-white text-center font-weight-bold">{{$t("trainView_btn_start_new_travel")}}</p>
            </template>
      </div>

  </div>
</template>

<script>
var MQTT = require("@/assets/js/MqttService");
import Product from "@/components/Product";

export default {
    name: 'WorldBoat',
    components: {Product},
    data: () => ({
        stations: ["Europe Paris", "Europe Marseille","Kiel", "hamburg", "Copenhague", "Savone", "Valence", "Toulon-Seyne sur Mer"],
        vehicle: {
            type: ["EXPEDITION", "STANDALONE CRUISE", "CRUISE & FLIGHT","RIVER CRUISE"],
            number: ["10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
            price: [438, 550, 334, 342, 513, 562, 537, 630, 749, 638, 341, 355, 732],
            hour: ["07:00 - 12:30", "08:00 - 13:00", "08:30 - 14:00", "09:00 - 15:00", "09:00 - 15:00", "11:30 - 6:00", "12:00 - 17:00", "13:00 - 17:30", "14:00 - 18:30", "15:00 - 20:30", "16:00 - 21:00", "17:00 - 22:30", "17:30 - 22:00", "18:00 - 23:30", "19:00 - 00:30"]
        },
        lastTravelId: 1,
        travels: [
            {
                id: 0,
                startingStation : null,
                destinationStation: null,
                date: null,
                hour: null,
                price: null,
                train: null,
                options: [],
                alreadySent: false
            },
            {
                id: 1,
                startingStation : null,
                destinationStation: null,
                date: null,
                hour: null,
                price: null,
                train: null,
                options: [],
                alreadySent: false
            }
        ],
        customerName: null,
        totalAmount: 0,
        processStarted: false,
        showAddNew: false,
        canPay: false,
        simulation: "",
        terminalId: MQTT.getTerminalId()
    }),
    created() {
        this.sendEnd()
        this.simulation = this.$route.name
        MQTT.eventBus.on('terminalIdChanged', this.onTerminalIdChanged);
        if (this.terminalId) {
            MQTT.subscribeScanLoyalty((data) => {
                this.customerName = data
            })

            // MQTT.subscribeEnd(this)
        }
    },
    beforeDestroy() {
        MQTT.eventBus.off('terminalIdChanged', this.onTerminalIdChanged);
    },
    methods: {
        handleStartStop(){
            if(this.processStarted){
                this.sendEnd()
            }else{
                this.sendStart()
            }
        },
        sendStart(){
            MQTT.publishMessage("SEND_START", "")
            this.processStarted = true
            this.canPay = false
        },
        sendEnd(){
            MQTT.publishMessage("SEND_END", "", () => {
                this.reloadTravel()
                // window.location.reload()
            })
        },
       sendPayment(){
            if (this.canPay) {
                MQTT.publishMessage("SEND_PAYMENT", "")
            }
        },
        sendLoyalty(){
            MQTT.publishMessage("SEND_LOYALTY", "")
        },
        updateTravel(travel){
            let index = this.travels.findIndex(tempTravel => tempTravel.id === travel.id)
            this.travels[index] = travel
        },
        addNewTravel(){
            let travel = {...this.travels.find(travel => travel.id === 0)}
            this.lastTravelId++
            travel.id = this.lastTravelId
            this.travels.push(travel)
            this.showAddNew = false
        },
        formatPrice(price){
            return price.toFixed(2).replace(".", ",") + "€" 
        },
        calculateTotalAmount(){
            this.showAddNew = true
            this.canPay = true
            this.totalAmount = this.travels.reduce((acc, travel) => acc+= travel.price ? travel.price : 0 , 0)
        },
        reloadTravel(){
            this.canPay = false
            this.processStarted = false
            this.totalAmount = 0
            this.travels = [
                {
                    id: 0,
                    startingStation : null,
                    destinationStation: null,
                    date: null,
                    hour: null,
                    price: null,
                    train: null,
                    options: [],
                    alreadySent: false
                },
                {
                    id: 1,
                    startingStation : null,
                    destinationStation: null,
                    date: null,
                    hour: null,
                    price: null,
                    train: null,
                    options: [],
                    alreadySent: false
                }
            ],
            this.showAddNew = false
            this.customerName = null
        },
        onTerminalIdChanged(newId) {
            this.terminalId = newId;
            console.log('Watched terminalId changed:', newId);
        }

    },
    watch: {
        terminalId(newId) {
            MQTT.subscribeScanLoyalty((data) => {
                this.customerName = data
            })
            
            // MQTT.subscribeEnd(this)

            console.log('Watched terminalId changed:', newId);
        }
    }
}
</script>

<style>
.worldBoat{
    display: flex;
    flex-direction: row;
}

.first-column{
    flex: 1;
    height: fit-content;
}

.second-column{
    flex: 2;
}

.customer-name{
    font-size: 1.5rem;
}
</style>
