<template>
  <div class="worldFlight align-stretch">

       <div class="first-column d-flex flex-column">
          <div class="d-flex flex-column pa-4 background-app">
              <div v-if="customerName" class="d-flex align-center justify-center">
                  <span class="text-white text-decoration-underline customer-name">{{customerName}}</span>
                  <a href="#"><v-icon small color="white" class="ms-2">mdi-information-slab-circle</v-icon></a>
              </div>
              <span class="text-white text-center mb-4" v-if="customerName">{{miles}} Miles points</span>
    
              <span class="text-white text-center font-weight-bold">{{$t("trainView_text_client")}}</span>
    
              <v-btn @click="handleStartStop()" class="mt-3">{{processStarted ? $t("trainView_btn_interrupt") : $t("trainView_btn_start")}} {{$t("trainView_btn_text_travel")}}</v-btn>
    
              <v-btn  @click="sendLoyalty()" :disabled="processStarted == false || this.totalAmount == 0" class="mt-3">{{$t("trainView_btn_scan_loyalty")}}</v-btn>
    
              <v-btn @click="sendPayment()" :disabled="processStarted == false" class="mt-3">{{$t("trainView_btn_checkout")}}</v-btn>
          </div>
          <div v-if="totalAmount > 0" class="my-2 d-flex pa-4 background-app">
            <p class="d-flex flex-column mb-0 text-h5 text-white font-weight-bold">
                <span>{{$t("trainView_text_total")}}</span>
                <span class="text-caption font-italic">{{$t("trainView_text_Microdon")}}</span> 
            </p>
            <p class="mb-0 mx-2 text-h5 text-white text-center font-weight-bold">{{formatPrice(totalAmount)}}</p>
          </div>
      </div>

      <div class="second-column ms-4" :class="{'background-app d-flex flex-column align-center justify-center': !processStarted}">
            <template v-if="processStarted">
                <template v-for="travel in travels">
                    <div class="background-app mb-4" :key="travel.id" v-if="travel.id !== 0">
                        <Product :travel="travel" :simulation="simulation" :station="stations" :train="vehicle" @updateTravel="updateTravel" @sendProduct="calculateTotalAmount()"/>
                    </div>
                </template>
        
                <div class="d-flex justify-end">
                    <v-btn class="text-white" color="#127996" v-show="showAddNew" @click="addNewTravel">{{$t("trainView_btn_add_new_travel")}}</v-btn>
                </div>

            </template>
            <template v-else>
              <p class="mb-0 text-body-1 text-white text-center font-weight-bold">{{$t("trainView_btn_start_new_travel")}}</p>
            </template>

              
      </div>

  </div>
</template>

<script>
var MQTT = require("@/assets/js/MqttService");
import Product from "@/components/Product";

export default {
    name: 'WorldFlight',
    components: {Product},
    data: () => ({
        stations: ["Paris","Nice, aéroport Côte d'Azur", "Marseille","Lyon","Bordeaux", "Toulouse, aéroport Blagnac", "Nantes", "Lille", "Strasbourg","Montpellier"],
        vehicle: {
            // type: ["ECONOMY", "PREMIUM ECONOMY", "BUSINESS","LA PREMIERE"],
            type: ["ECONOMY", "PREMIUM ECONOMY", "BUSINESS","FIRST CLASS"],
            number: ["7397", "7668", "1342", "1590", "2210", "3925", "3168", "6179", "4392", "5437", "6664", "8829", "1613", "4922", "2164", "8309", "7353", "7058", "1729", "5739", "6948", "2913", "2047", "4864", "5133", "5862", "6723", "8719", "1059", "5218", "7985", "7796", "8651", "8684", "8452", "5347", "7157", "5415", "3421", "8705", "3143", "3426", "1436", "2275", "3959", "5587", "5912", "1409", "7655", "7498", "1668", "4656", "3985", "2617", "1879", "8508", "2146", "1404", "6532", "8905", "5321", "6681", "1361", "1971", "4414", "7405", "6142", "5031", "2465", "6184", "7804", "1976", "2726", "4821", "5064", "6358", "8889", "4861", "2279", "4843", "1256", "2086", "5485", "4878", "2841", "2394", "5855", "2396", "3162", "7635", "7396", "3039", "2551", "4114", "1102", "4937", "3201", "7784", "5916", "1766"],
            price: [381, 302, 347, 395, 228, 158, 372, 305, 400, 386, 311, 296, 323],
            hour: ["07:25 - 12:25", "08:10 - 13:15", "08:45 - 14:55", "09:05 - 15:05", "09:15 - 15:55", "11:30 - 16:10", "12:05 - 17:15", "13:00 - 17:45", "14:05 - 18:45", "15:40 - 20:45", "16:15 - 21:15", "17:05 - 22:25", "17:40 - 22:55", "18:20 - 23:45", "19:10 - 00:55"]
        },
        miles: "15 000",
        lastTravelId: 1,
        travels: [
            {
                id: 0,
                startingStation : null,
                destinationStation: null,
                date: null,
                hour: null,
                price: null,
                train: null,
                options: [],
                alreadySent: false
            },
            {
                id: 1,
                startingStation : null,
                destinationStation: null,
                date: null,
                hour: null,
                price: null,
                train: null,
                options: [],
                alreadySent: false
            }
        ],
        customerName: null,
        totalAmount: 0,
        processStarted: false,
        showAddNew: false,
        canPay: false,
        simulation: "",
        terminalId: MQTT.getTerminalId()
    }),
     created() {
        this.sendEnd()
        this.simulation = this.$route.name
        MQTT.eventBus.on('terminalIdChanged', this.onTerminalIdChanged);
        console.log('terminal id', this.terminalId);

        this.miles = "15 000"
        if (this.terminalId) {
            MQTT.subscribeTopics({
                onScanLoyalty: (data) => {
                    this.customerName = data;
                },
                onUseMiles: () => {
                    this.miles = "0"; 
                }
            },this);
        }
        // if (this.terminalId) {
        //     MQTT.subscribeScanLoyalty((data) => {
        //         this.customerName = data
        //     })
        //     MQTT.subscribeUseMiles(() => {
        //         this.miles = "0"
        //     })

        // }
    },
    beforeDestroy() {
        MQTT.eventBus.off('terminalIdChanged', this.onTerminalIdChanged);
    },
    methods: {
        handleStartStop(){
            if(this.processStarted){
                this.sendEnd()
                this.miles = "15 000"
            }else{
                this.sendStart()
            }
        },
        sendStart(){
            MQTT.publishMessage("SEND_START", "")
            this.processStarted = true
            this.canPay = false
        },
        sendEnd(){
            MQTT.publishMessage("SEND_END", "", () => {
                this.reloadTravel()
                // window.location.reload()
            })
        },
        sendLoyalty(){
            MQTT.publishMessage("SEND_LOYALTY", "")
        },
        sendPayment(){
            if (this.canPay) {
                MQTT.publishMessage("SEND_PAYMENT", "")
            }
        },
        updateTravel(travel){
            let index = this.travels.findIndex(tempTravel => tempTravel.id === travel.id)
            this.travels[index] = travel
        },
        addNewTravel(){
            let travel = {...this.travels.find(travel => travel.id === 0)}
            this.lastTravelId++
            travel.id = this.lastTravelId
            this.travels.push(travel)
            this.showAddNew = false
        },
        formatPrice(price){
            return price.toFixed(2).replace(".", ",") + "€" 
        },
        calculateTotalAmount(){
            this.showAddNew = true
            this.canPay = true
            this.totalAmount = this.travels.reduce((acc, travel) => acc+= travel.price ? travel.price : 0 , 0)
        },
        reloadTravel(){
            this.canPay = false
                this.processStarted = false
                this.totalAmount = 0
                this.travels = [
                    {
                        id: 0,
                        startingStation : null,
                        destinationStation: null,
                        date: null,
                        hour: null,
                        price: null,
                        train: null,
                        options: [],
                        alreadySent: false
                    },
                    {
                        id: 1,
                        startingStation : null,
                        destinationStation: null,
                        date: null,
                        hour: null,
                        price: null,
                        train: null,
                        options: [],
                        alreadySent: false
                    }
                ],
                this.showAddNew = false
                this.customerName = null
        },
        onTerminalIdChanged(newId) {
            this.terminalId = newId;
            console.log('Watched terminalId changed:', newId);
        }

    },
    watch: {
        terminalId(newId) {
            this.miles = "15 000"
            MQTT.subscribeTopics({
                onScanLoyalty: (data) => {
                    this.customerName = data;
                },
                onUseMiles: () => {
                    this.miles = "0"; 
                    this.totalAmount -= 400
                }
            },this);


            console.log('Watched terminalId changed:', newId);
        }
    }
}
</script>

<style>
.worldFlight{
    display: flex;
    flex-direction: row;
}

.first-column{
    flex: 1;
    height: fit-content;
}

.second-column{
    flex: 2;
}

.customer-name{
    font-size: 1.5rem;
}

</style>
