import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './i18n'
import moment from "moment";
import store from './store'
import WorldBoatView from '@/views/WorldBoatView';
import WorldCarView from '@/views/WorldCarView';
import WorldTrainView from '@/views/WorldTrainView';
import WorldFlightView from '@/views/WorldFlightView';

var MQTT = require("@/assets/js/MqttService");

require('@/assets/css/custom.css');

Vue.config.productionTip = false

Vue.prototype.$moment = moment
Vue.prototype.$mqtt = MQTT

Vue.component('WorldTrainView', WorldTrainView);
Vue.component('WorldBoatView', WorldBoatView);
Vue.component('WorldCarView', WorldCarView);
Vue.component('WorldFlightView', WorldFlightView);

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
