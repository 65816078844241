<template>
    <div>
        <v-dialog
        v-model="showDialog"
        scrollable
        width="600"
        height="600"
        persistent
        >
            <v-card>
                <v-img height="572px" width="600" :src="require('@/assets/img/seatPlane.png')" contain >
                    <v-btn
                        icon
                        @click="cancel"
                    
                        class="close-icon float-right"
                        >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                
                    <v-btn
                    v-for="(seat, index) in seats"
                    :key="index"
                    :style="seat.position"
                    :class="{
                        'selected-seat': placeSelected === seat.label,
                        'default-seat': placeSelected !== seat.label
                    }"
                    density="compact"
                    x-small
                    width="20px"
                    height="20"
                    :color="placeSelected === seat.label ? 'primary' : 'white'"
                    class="seat-btn"
                    @click="selectSeat(seat)"
                    @mouseover="hoveredSeat = seat.label"
                    @mouseleave="hoveredSeat = null"
                    :elevation="hoveredSeat === seat.label ? 8 : 2"
                    >
                    {{ seat.label }}
                    </v-btn>
                </v-img>
                    
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="cancel()"
                    >
                    {{$t("options_btn_cancel")}}
                    </v-btn>

                    <v-btn
                        color="#127996"
                        text
                        @click="save()"
                    >
                        {{$t("options_btn_save")}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "seatPlaneSelecting",
    props: [ 'optionsFlight'],
    data: () => ({
        showDialog: true,
        placeSelected: null,
        hoveredSeat: null,
        seats: [
            { label: '1D', position: 'top: 146px; left: 260px;' },
            { label: '1E', position: 'top: 146px; left: 262px;' },
            { label: '1F', position: 'top: 146px; left: 263px;' },
            { label: '1G', position: 'top: 146px; left: 265px;' },
            
            { label: '2D', position: 'top: 176px; left: 180px;' },
            { label: '2E', position: 'top: 176px; left: 182px;' },
            { label: '2F', position: 'top: 176px; left: 183px;' },
            { label: '2G', position: 'top: 176px; left: 186px;' },
            
            { label: '3D', position: 'top: 206px; left: 100px;' },
            { label: '3E', position: 'top: 206px; left: 102px;' },
            { label: '3F', position: 'top: 206px; left: 103px;' },
            { label: '3G', position: 'top: 206px; left: 106px;' },
            
            { label: '4D', position: 'top: 236px; left: 20px;' },
            { label: '4E', position: 'top: 236px; left: 22px;' },
            { label: '4F', position: 'top: 236px; left: 23px;' },
            { label: '4G', position: 'top: 236px; left: 25px;' },
            
            { label: '5D', position: 'top: 266px; left: -60px;' },
            { label: '5E', position: 'top: 266px; left: -58px;' },
            { label: '5F', position: 'top: 266px; left: -55px;' },
            { label: '5G', position: 'top: 266px; left: -54px;' },
            
            { label: '6D', position: 'top: 296px; left: -140px;' },
            { label: '6E', position: 'top: 296px; left: -138px;' },
            { label: '6F', position: 'top: 296px; left: -135px;' },
            { label: '6G', position: 'top: 296px; left: -133px;' },
        ],
    }),
    created() {
        if (this.optionsFlight) {
            this.placeSelected = this.optionsFlight.seat;
        }
    },
    methods: {
        save(){
            this.$emit("modalClosed", this.placeSelected)
        },
        cancel(){
            this.$emit("modalClosed")
        },
        selectSeat(seat) {
            this.placeSelected = seat.label;
        },
    },
}
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--x-small {
    min-width: 10px;
    padding: 0px 0px;
}
.selected-seat {
  background-color: #1e3a8a !important; 
  color: white !important; 
}

.default-seat {
  background-color: primary !important; 
  color: black !important; 
}
</style>
