<template>
    <v-dialog
        v-model="showDialog"
        scrollable
        persistent
        max-width="500px"
    >
        <v-card
            width="500"
        >
            <v-card-title>Select your terminal</v-card-title>
            <v-card-text>
                <v-chip-group
                    v-if="TerminalsId.length > 0"
                    v-model="selection"
                    active-class="primary accent-4 white--text"
                    column
                >
                    <v-chip v-for="terminal in TerminalsId" @click="selectTerminalId(terminal)" :key="terminal">{{terminal}}</v-chip>

                </v-chip-group>
                <p v-else>No terminal Found</p>
            </v-card-text>
            <v-card-actions >
                <div v-if="!idIsSelected" class="red--text font-italic">Terminal id is not selected</div>
                <v-spacer></v-spacer>
                <v-btn
                    @click="savedIdTerminal"
                    rounded
                    color="primary"
                >
                    Validate
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
var MQTT = require("@/assets/js/MqttService");
export default {
    name: "ManagerTerminalIdModal",
    props: ["TerminalsId","showModalTerminal"],
    data: () => ({
        showDialog: true,
        selection: null,
        idSelected: null,
        idIsSelected: true,
    }),
    created() {
        this.showDialog = this.showModalTerminal
    },
    methods:{
        selectTerminalId(id){
            this.idSelected = id
        },
        savedIdTerminal(){
            if(this.selection != null){
                this.$mqtt.setTerminalId(this.idSelected)
                this.showDialog = false
                MQTT.publishMessage('SEND_CONNECTED', this.idSelected);
            }else{
                this.idIsSelected = false
            }
        }
    },
}
</script>

<style scoped>

</style>
